<template>
  <div class="main1">
    <div class="showhotspot">
      <div class="main-title">热点资讯</div>
      <div class="driver"></div>
      <div class="hotContent">
        <div class="hotContent-left">
          <div style="height: 20vw">
            <img style="border-radius: 20px;height: 100%;width: 100%" :src="blogs0.cover_image" alt="">
          </div>
          <h4 @click="toMainUrl(blogs0.url)">{{ blogs0.article_name}}</h4>
          <p>{{ blogs0.short_intro }}</p>
          <p class="time"><b-icon icon="stopwatch"></b-icon>&nbsp;&nbsp;&nbsp;{{ blogs0.create_date }}</p>
        </div>
        <div class="hotContent-right">
          <div class="hot-right" v-for="item of blogs">
            <div class="hot-right-left">
              <div style="height: 10vw">
                <img style="border-radius: 20px;height: 100%;width: 100%" :src="item.cover_image" alt="">
              </div>
            </div>
            <div class="hot-right-right">
              <h4 @click="toMainUrl(item.url)">{{ item.article_name }}</h4>
              <p>{{ item.short_intro }}</p>
              <p class="time"><b-icon icon="stopwatch"></b-icon>&nbsp;&nbsp;&nbsp;{{ item.create_date }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="show" class="myBtn" @click="toMore()">更多咨讯</div>
    </div>
  </div>
</template>

<script>
import {httpService} from "@/services/http";
import {constants} from "@/services/constants";

export default {
  name: "showhotspot",
  data(){
    return{
      blogs0:'',
      blogs:'',
      show:true
    }
  },
  created() {
    httpService.get(constants.BLOGS).then((data) => {//获取资讯信息
      this.blogs0 = data.body.results[0]
      this.blogs = data.body.results.slice(1,4)
      window.sessionStorage.setItem('blogData',JSON.stringify(data.body))
    })
    if (this.$route.path === "/news"){
      this.show = false
    }

  },
  methods:{
    toMainUrl(url){//跳转到资讯详情页
      window.open(url,"_blank")
    },
    toMore(){
      this.$router.push('/news')
    }
  }
}
</script>

<style lang="scss" scoped>
//*{
//  outline: 1px solid red;
//}
.main1{
  h4:hover{
    text-decoration: underline;
    cursor: pointer;
  }
  background-color: rgba(247, 247, 247, 0.9);
  margin-top: 5vh;
  padding: 4vh 0;
  .showhotspot{
    width: 80%;
    margin: 0 auto;
  }
  .hotContent{
    p{opacity: .7}
    .time{
      font-size: 13px;
    }
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .hotContent-left{
      width: 45%;
      img{
        width: 100%;
      }
      h4{
        margin-top: 40px;
      }
    }
    .hotContent-right{

      width: 45%;
      .hot-right{
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        .hot-right-left{
          width: 45%;
          img{
            width: 100%;
          }
        }
        .hot-right-right{
          width: 45%;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .main{
    background-color: rgba(247, 247, 247, 0.9);
    margin-top: 30px;
    .showhotspot{
      width: 80%;
      margin: 0 auto;
    }
    .hotContent{
      p{opacity: .7}
      .time{
        font-size: 13px;
      }
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      .hotContent-left{
        width: 600px;
        img{
          width: 100%;
        }
        h4{
          margin-top: 40px;
        }
      }
      .hotContent-right{
        margin-top: 20px;
        width: 600px;
        .hot-right{
          margin-bottom: 20px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;
          .hot-right-left{
            width: 275px;
            img{
              width: 100%;
            }
          }
          .hot-right-right{
            width: 275px;
          }
        }
      }
    }
  }
  .main-title{
    font-size: 35px;
    text-align: center;
    margin-top: 40px;
  }
  .driver{
    height: 4px;
    text-align: center;
    width: 50px;
    background-color:#007DD1 ;
    margin: 20px auto;
  }
}
</style>