<template>
<div class="top3">
  <div class="main-title">热门院校</div>
  <div class="driver"></div>
  <div>
    <el-carousel :interval="4000" indicator-position="outside" height="22vw">
      <el-carousel-item indicator-position="none" v-for="item in hotSubjects" :key="item.id">
<!--        <div style="display: flex;margin-top: .4vw">-->
<!--          <div style="width: 25vw;margin-left: 1.2vw" v-for="itemSubject of item.subjects" :key="itemSubject.id">-->
<!--            <div style="padding: 0 3vw;box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);border-radius: 10px;">-->
<!--              <div style="width:100%;padding-top: .5vw">-->
<!--                <img width="100%" height="100%" :src="itemSubject.school_logo" alt="">-->
<!--              </div>-->
<!--              <div style="margin-top: 1.5vw;padding: 0 0 1vw 1vw">-->
<!--                <h4 style="cursor: pointer">{{ itemSubject.name_chs }}<i @click="toIntroduce(itemSubject)" style="margin-left: 10vw;color: blue" class="el-icon-right"></i></h4>-->
<!--                <p>{{ itemSubject.school_types[0].type}}</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;              <majorCard></majorCard>&ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->
        <div style="display: flex;margin-top: .4vw;justify-content: space-between;padding: 0 .2vw">
          <schoolCard
              style="border-radius: 20px;box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);"
              v-for="itemSubject of item.subjects" :key="itemSubject.id"
              :type="itemSubject.school_types"
              :img="itemSubject.school_logo"
              :title="itemSubject.name_chs"
              :subTitle="itemSubject.name_eng"
              :message="itemSubject.sub_title"
              :rank="itemSubject.rank"
              @click.native="toIntroduce(itemSubject)"
          >
<!--            <div style="padding: 0 3vw;box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);border-radius: 10px;">-->
<!--              <div style="width:100%;padding-top: .5vw">-->
<!--                <img width="100%" height="100%" :src="itemSubject.school_logo" alt="">-->
<!--              </div>-->
<!--              <div style="margin-top: 1.5vw;padding: 0 0 1vw 1vw">-->
<!--                <h4 style="cursor: pointer">{{ itemSubject.name_chs }}<i @click="toIntroduce(itemSubject)" style="margin-left: 10vw;color: blue" class="el-icon-right"></i></h4>-->
<!--                <p>{{ itemSubject.school_types[0].type}}</p>-->
<!--              </div>-->
<!--            </div>-->
            <!--              <majorCard></majorCard>-->
          </schoolCard>
        </div>
<!--        <schoolCard-->
<!--            :img="item.school_logo"-->
<!--            :title="item.name_chs"-->
<!--            :subTitle="item.name_eng"-->
<!--            :message="item.sub_title"-->
<!--            :rank="typeof(item.rank) == 'number'?item.rank:'暂无'"-->
<!--        ></schoolCard>-->
      </el-carousel-item>
    </el-carousel>

  </div>
</div>
</template>

<script>
import {schoolService} from "@/services/school";
import {constants} from "@/services/constants";
import {httpService} from "@/services/http";
import schoolCard from "@/components/showSchool/schoolCard";

export default {
  name: "top3School",
  components:{
    schoolCard
  },
  data(){
    return{
      hotSubjects:[
        {id:1,subjects:[]},
        // {id:2,subjects:[]},
        // {id:3,subjects:[]},
      ]
    }
  },
  activated() {
    console.log('Foo activated');
  },
  deactivated() {
    console.log('Foo deactivated');
  },
  created() {
    httpService.get(constants.SCHOOL_LIST+6).then((data) => {
      this.hotSubjects[0].subjects.push(data.body)
      console.log(data)
    })
    httpService.get(constants.SCHOOL_LIST+5).then((data) => {
      this.hotSubjects[0].subjects.push(data.body)
    })
    httpService.get(constants.SCHOOL_LIST+15).then((data) => {
      this.hotSubjects[0].subjects.push(data.body)
    })
  },
  methods: {
    toIntroduce(item) {//跳转到点击学校的详细介绍
      this.$router.push({path:'/schools/schoolIntroduce',query:{id:item.id}})
    }
  }
}
</script>

<style lang="scss" scoped>
//.top3 *{
//  outline: 1px solid red;
//}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 20vw;
  margin: 0;

}


.top3{
  width: 80%;
  margin: 0 auto;
}
.main-title{
  font-size: 35px;
  text-align: center;
  margin-top: 40px;
}
.driver{
  height: 4px;
  text-align: center;
  width: 50px;
  background-color:#007DD1 ;
  margin: 20px auto;
}
//.client-feedback-wrapper{
//  height: 70vh;
//}
.slide{
  height: 33vw;
  border-radius: 20px;
  position: relative;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  span{
    position: absolute;
    top: 80%;
    left: 80%;
  }
  @media screen and (max-width: 700px) {
    span{
      position: absolute;
      top: 87%;
      left: 80%;
    }
  }
}
@media screen and (max-width: 700px) {
  .slide{
    height: 33vw;
  }
}
.swiper-pagination{
  margin-top: 20px;
}
</style>
