<template>
  <div class="main-container">

<!--    <HeroOne/>-->

    <keep-alive>
       <top3School></top3School>
    </keep-alive>
    <ShowSchool :getNext="getNext" :schoolType="schoolType" ></ShowSchool>


    <showhotspot></showhotspot>



    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="60px">
      <i class="ion-android-arrow-up"></i>
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import data from "../data/service.json";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";
import { schoolService } from "../services/school";
import InfiniteLoading from "vue-infinite-loading";
import HeroOne from "@/components/sections/HeroOne";
import searchBox from "@/components/Searchbox/searchBox";
import top3School from "@/components/top3/top3School";
import ShowSchool from "@/components/showSchool/showSchool";
import showhotspot from "@/components/showhotspot";
import {httpService} from "@/services/http";
import {constants} from "@/services/constants";

export default {
  components: {
    OffCanvasMobileMenu,
    InfiniteLoading,
    HeroOne,
    searchBox,
    top3School,
    ShowSchool,
    showhotspot,
  },
  data() {
    return {
      top3Data:[
        {}
      ],
      header:"",
      data,
      schoolType:'',
      schools:'',
      next:'',
      count:''
    };
  },
  watch: {
    $route: "fetchData",
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.schoolType = this.$route.query.t;
      this.getAllSch(this.schoolType, "");
      this.getAllSubject()
    },

    // async clickPrevious() {
    //   this.getAllSch(this.schoolType, this.previous);
    // },
    //
    // async clickNext() {
    //   this.getAllSch(this.schoolType, this.next);
    //
    // },

    async getAllSch(schoolType, params) {
      let schools = await schoolService.getAllSchools(schoolType, params);
      this.schools = schools.results;
      this.count = schools.count;
      this.next = schools.next;
      this.previous = schools.previous;
      this.$store.state.SchoolList = this.schools
    },
    getNext(){
      if (this.$store.state.SchoolList.length < this.count){//加入判断，如果展示完了所有院校，那么就不再发送请求
        httpService.get(this.next).then((data) => {
          this.$store.state.SchoolList = this.$store.state.SchoolList.concat(data.body.results)
          this.next = data.body.next
        })
      }else {
        alert('没有更多院校了~~~')
      }

    },
    async getAllSubject() {
      let subjects = await schoolService.getSubjectTypes();
      //console.log(subjects);
    },
  },
};
</script>
<style>
/**{*/
/*  outline: 1px solid red;*/
/*}*/
.Box{
  margin-top: -50px;
  z-index: 999;
  position: relative;
}
</style>

