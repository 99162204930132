<template>
<div class="showSchool">
  <div class="main-title">{{ schoolType }}</div>
  <div class="driver"></div>
  <div>
    <div class="content">
      <schoolCard class="myCard"
                  :key="item.id"
                  v-for="item in $store.state.SchoolList"
                  :type="item.school_types"
                  :img="item.school_logo"
                  :message="item.sub_title"
                  :title="item.name_chs"
                  :subTitle="item.name_eng"
                  :rank="item.rank"
                  @click.native="toIntroduce(item)"
      ></schoolCard>
    </div>
    <div class="myBtn" @click="getNext()">更多院校</div>
  </div>
</div>
</template>

<script>
import schoolCard from "@/components/showSchool/schoolCard";
import img from '../../assets/myImage/school5.png'
export default {
  name: "showSchool",
  props:['schoolType','getNext'],
  components:{
    schoolCard
  },
  methods:{
    toIntroduce(item) {//跳转到点击学校的详细介绍
      this.$router.push({path:'/schools/schoolIntroduce',query:{id:item.id}})
    }
  }
}
</script>

<style scoped>
/*.showSchool,.showSchool *{*/
/*  outline: 1px solid red;*/
/*}*/
.container{
  max-width: 100%;
}
.myCard{
  border-radius: 20px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  margin-top: 5vh;
}
.showSchool{
  width: 80%;
  margin: 0 auto;
}
.content{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
