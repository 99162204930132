<template>
  <div class="main">
    <div class="searchBox">
      <div class="col-lg-5 line">
        <div>方向</div>
        <select v-model="val1" @change="show(val1)">
          <option v-for="item of types" :key="item.id" :value="item.name">{{ item.name }}</option>
        </select>
      </div>
      <div class="col-lg-5 line">
        <div>专业</div>
        <select v-model="val2">
          <option v-for="item of subTypes.subject_type_sub" :key="item.id" :value="item.name">{{ item.name }}</option>
        </select>
      </div>
      <div class="col-lg-2 line" style='line-height: 60px;text-align: center'>
        <b-button variant="primary" @click="toSubMajor(val1,val2)">搜索</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {schoolService} from "@/services/school";

export default {
  name: "searchBox",
  data(){
    return{
      types:'',
      val1:'',
      val2:'',
      subTypes:''
    }
  },
  created() {
    schoolService.getSubjectTypes().then((data) => {
      this.types = data
      this.subTypes = data[0]
      this.val1 = data[0].name
      this.val2 = data[0].subject_type_sub[0].name
    })
  },
  methods:{
    show(val1){//展示一二级专业分类，进行多级联动搜素
      this.subTypes = this.types.find((item) => {
        return val1 === item.name
      })
      // this.val2 = this.subTypes.subject_type_sub[0].name
      if (this.subTypes.subject_type_sub.length === 0){
        console.log(111)
      }else {
        this.val2 = this.subTypes.subject_type_sub[0].name
      }
    },
    toSubMajor(val1,val2){
      this.$router.push({path:'/project-details',query:{main0:val1,main:val2}})
    }
  }
}
</script>

<style lang="scss" scoped>
//.searchBox *{
//  outline: 1px solid red;
//}
.searchBox{
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  height: 20%;
  background: #FFFFFF;
  box-shadow: 0px 10px 50px rgba(131, 131, 131, 0.4);
  border-radius: 20px;
  margin: 30px auto 0;
  padding: 30px 50px;
  .line{
    margin-top: 20px;
  }
  select{
    width: 100%;
    border: none;
    border-bottom: solid #007DD1;
    font-weight: 600;
    height: 30px;
    margin-top: 10px;
    background-color: white;
  }
}
</style>
