<template>
  <div>
    <div class="schoolCard1">
      <div class="img">
        <img :src="img" alt="">
      </div>
      <div class="introduce">
        <div class="introduce-left" v-if="isGongLi">
          <h6>QS</h6>
          <h6>Ranking</h6>
          <h5>{{ rank }}</h5>
        </div>
        <div class="introduce-right">
          <p class="myT">{{ title }}</p>
          <p class="myS">{{ subTitle }}</p>
          <p class="mt">{{ message }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "schoolCard",
  props:['img','title','subTitle','message','rank','type'],
  data(){
    return{
      isGongLi:false
    }
  },
  created() {
    this.type.map((item) => {
      if (item.type == '公立大学'){
        this.isGongLi = true
        return
      }
    })
  },

}
</script>

<style lang="scss" scoped>
//*{
//  outline: 1px solid red;
//}
.main{
  margin: 0 auto;
}
.schoolCard1{

  //overflow: hidden;
  //height: 60vh;
  width: 23vw;
  //border-radius: 20px;
  //box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  cursor: pointer;
  padding: 0 0 2vh 0;
}
.img{
  height: 12vw;
  width: 20vw;
}
.img img{
  width: 100%;
  height: 100%;
}
.introduce{
  padding: 0 15px 0 0;
  display: flex;
  justify-content: space-evenly;
  .introduce-left{
    width: 25%;
    text-align: center;
    h6{
      color:#007DD1;
    }
  }
  .introduce-right{
    width: 75%;
    .mt{
      opacity: .7;
      font-size: 2%;
      margin-top: 10px;
      line-height: 2vh;
    }
  }
}
.myT{
  font-weight: bold;
  font-size: 1.4vw;
  margin-bottom: 0;
}
.myS{
  font-weight: bold;
  font-size: 1vw;
  margin-bottom: 0;
}
</style>
